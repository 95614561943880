import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TransferService {

  constructor(
    private http: HttpClient
  ) { }

  FetchLocalPayments(data)
  {
    return this.http.post<any>(`${environment.devUrl.transService}Payment/GetlocalPayment`, data)
  }
  MakeOnScreenBulk(data)
  {
    return this.http.post<any>(`${environment.devUrl.transService}Payment/MakeOnScreenBulkLocalPayment`, data)
  }
}
