import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/services/auth.service';
declare var $:any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  LoginForm: FormGroup;
  tokenReq:boolean =  false;
  userData: string;

  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private auth: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.LoginForm = this.fb.group({
      OrgCode: ['', [Validators.required, Validators.minLength(3)]],
      username: ['', [Validators.required, Validators.minLength(3)]],
      password: ['', [Validators.required, Validators.minLength(3)]],
      token: ['']
    })
  }



  Login(data)
  {
    this.spinner.show()
   let body = {
    "CorporateCode": data.OrgCode,
    "Username": data.username,
    "Password": data.password,
    "IpAddress": "10.2.2.2",
    "MacAddress": "00:1B:44:11:3A:B7"
   }
   this.auth.login(body).subscribe(
     res =>{
       this.spinner.hide()
       if(res.success)
       {
        sessionStorage.setItem('username',body.Username+'@'+body.CorporateCode)
        this.handleLogin(res)
       }
       else{
         this.toastr.error('Invalid Login Credentials','Please try again!')
       }       
     },
     err=>{
       this.spinner.hide()
       this.toastr.error('Unable to complete that','Please try later')
       this.LoginForm.reset()
     }
   )
  }

  handleLogin(data)
  {
    this.userData = JSON.stringify(data)
   if(data.tokenRequired)
   {
     this.toastr.info('Enter Token to Proceed')
     this.spinner.hide()
     this.tokenReq = true;   
    $('#org').attr('disabled', 'disabled')
    $('#username').attr('disabled', 'disabled')
    $('#pass').attr('disabled', 'disabled')
   }
   else{
     sessionStorage.setItem('userData', this.userData)
     this.toastr.success('Login Successful','Redirecting...')
          setTimeout(() => {
            this.router.navigate(['/dashboard'])
          }, 1500); 
   }
  }
  ValidateToken()
  {
    this.spinner.show()
    let body = {
      Username:this.LoginForm.value.username,
      Session:(JSON.parse(this.userData)).session,
      Token: this.LoginForm.get('token').value,
    }
    this.auth.verifyToken(body).subscribe(
      res=>{
        this.spinner.hide()
        if(res.success)
        { 
          sessionStorage.setItem('userData',this.userData)
          this.toastr.success('Token Validated Succesfully', 'Redirecting you...')
          setTimeout(() => {
            this.router.navigate(['/dashboard'])
          }, 1500);         
        }
        else{
          this.toastr.error('Incorrect Token','Please try again')
        }
      },
      err=>{
        this.toastr.error('Unable to Login', 'Please try later')
      }
    )
   
  }

}
