import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient
  ) { }


  login(data) {
    return this.http.post<any>(`${environment.devUrl.authService}Authentication/Login_Old`, data);
  }
  verifyToken(data)
  {
    return this.http.post<any>(`${environment.devUrl.authService}Authentication/LoginToken`, data);
  }
  userLogout(Username, Session)
  {
    return this.http.get<any>(`${environment.devUrl.authService}Authentication/Logout/${Username}/${Session}`)
  }

  RetrieveUsername(data)
  {
    return this.http.post<any>(`${environment.devUrl.authService}Authentication/ForgotUsername`, data);
  }
  InitiatePasswordChange(data)
  {
    return this.http.post<any>(`${environment.devUrl.authService}Authentication/ForgotPassword`, data);
  }
  ResetPassword(data)
  {
    return this.http.post<any>(`${environment.devUrl.authService}Authentication/ChangePassword`, data);
  }
}
