import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-username-reset',
  templateUrl: './username-reset.component.html',
  styleUrls: ['./username-reset.component.css']
})
export class UsernameResetComponent implements OnInit {
  ResetForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private auth:AuthService,
    private spinner:NgxSpinnerService,
    private toastr:ToastrService
  ) { }

  ngOnInit(): void {
    this.ResetForm = this.fb.group({
      email: ['', [Validators.required, Validators.minLength(3), Validators.email]],
    })
  }

  RetrieveUsername(data)
  {
    this.spinner.show()
    this.auth.RetrieveUsername(data).subscribe(
      res=>{
        this.spinner.hide()
        if(res.success)
        {
          this.toastr.success('Username has been sent to your registered email!')
          this.ResetForm.reset()
        }
        else{
          this.toastr.error('User not found','Please try again')
          this.ResetForm.reset()
        }
      },
      err=>{
        this.spinner.hide()
        this.toastr.error('Unable to perform that action','Please try again')
      }
    )
  }

}
