import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit,AfterViewInit {
  subsidiaries: any;
  fullName: any;

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private auth: AuthService,
    private router: Router
  ) { 
    // router.events.subscribe((url:any) => console.log(url));
    // console.log(router.url);  // to print only path eg:"/login"
  }



  ngOnInit(): void {
   // console.log(this.router.url); 
    this.ListSubsidiaries()
  }
  ngAfterViewInit(){
   // console.log(this.router.url); 
  }

  Logout()
  {
    this.spinner.show()
    this.auth.userLogout(sessionStorage.getItem('username'),(JSON.parse(sessionStorage.getItem('userData')).session)).subscribe(
      res=>{
          this.router.navigate(['/login'])
          this.spinner.hide()
          this.toastr.error('Something went wrong', 'Please try again')
          sessionStorage.clear()
      },
      err=>{
        this.spinner.hide()
        this.router.navigate(['/login'])
        this.toastr.error('Unable to perform that task','Redirecting you...')
      }
    )
  }
  ListSubsidiaries()
  {
   this.subsidiaries = ((JSON.parse(sessionStorage.getItem('userData'))).getSubsidiaries)
   this.fullName = ((JSON.parse(sessionStorage.getItem('userData'))).fullname)
  }

}
