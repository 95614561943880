import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup, FormArray, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TransferService } from 'src/app/core/services/transfer.service';

@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.css']
})
export class TransferComponent implements OnInit {
  SingleForm: any;
  localResponse: any;
  beneficiaryType: string;
  transRef: number;
  beneList:Array<any> = [];

  constructor(
    private fb: FormBuilder,
    private transfer: TransferService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.SingleForm = this.fb.group({
      beneficiaries: new FormArray([
          this.fb.group({
            name: [''],
            email: ['']
        })
      ])
    })
    //this.GetLocalPayments()
    console.log(this.getRef(12,36))
  }


  GetLocalPayments()
  {
    this.spinner.show()
    let body = {
      Session:(JSON.parse(sessionStorage.getItem('userData'))).session,
      Username: sessionStorage.getItem('username'),
      SubsidiaryId: (JSON.parse(sessionStorage.getItem('userData'))).getSubsidiaries[0].subsidiaryId
    }
    this.transfer.FetchLocalPayments(body).subscribe(
      res=>{
        this.spinner.hide();
        if(res.success)
        {
          this.localResponse = res;
        }
      },
      err=>{
        this.toastr.error('Unable to load Accounts','Redirecting...')
        this.spinner.hide()
        setTimeout(() => {
          this.router.navigate(['/login'])
        }, 1000);
      }
    )
  }
  get f() { return this.SingleForm.controls; }
  get t() { return this.f.beneficiaries as FormArray; }

  onAddBeneficiary() {
            this.t.push(this.fb.group({
                name: [''],
                email: ['']
            }));
      
     console.log(this.SingleForm.value)
}
ViewSubmission()
{
  console.log(this.SingleForm.value)
}
  setBeneficiary(data)
  {
    this.BeneficiaryType(data)
  } 
  BeneficiaryType(data)
  {
    switch(data)
    {
      case'saved':
      this.beneficiaryType = 'saved'
      break;
      case 'self':
        this.beneficiaryType = 'self'
      break;
      case 'new':
       this.beneficiaryType = 'new'
      break;
    } 
  }

  SinglePayment(data)
  {
    let body =
    {
      AccountId: 4,
      PaymentMethod: 1,
      SalaryPayment: true,
      Request: [
        {
          BankCode: "011",
          AccountNumber: "3026074640",
          PrefferedName: "string",
          TransactionRef: "12345676543",
          SaveBeneficiary: true,
          Amount: 1000,
          ValueDate: "22-01-2021",
          NotifyCustomer: true,
          ChargeBeneficiary: true,
          Narration: "food money",
        }
      ],
      Session:(JSON.parse(sessionStorage.getItem('userData'))).session,
      Username: sessionStorage.getItem('username'),
      SubsidiaryId: (JSON.parse(sessionStorage.getItem('userData'))).getSubsidiaries[0].subsidiaryId
    }
    this.transfer.MakeOnScreenBulk(body).subscribe(
      res=>{
        console.log(res)
      }
    )

  }
  getRef(len, bits) {
    bits = bits || 36;
    var outStr = "", newStr;
    while (outStr.length < len)
    {
        newStr = Math.round(Date.now() + Math.random()).toString(bits).slice(2);
        outStr += newStr.slice(0, Math.min(newStr.length, (len - outStr.length)));
    }
    return outStr.toUpperCase();
    }

  removeBeneficiary(data)
    {
      this.t.removeAt(data)
    }

}

// 1625240703910
