import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ManagementService } from 'src/app/core/services/management.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.css']
})
export class AccountsComponent implements OnInit {
  accounts: any;
  defId: any;
  selectedNumber: any;
  defaultAcc: any;
  transHistory: any;
  p:any;
  allHistory: any;
  FilterForm: FormGroup;
  allAccounts: any;
  checkedAcc = [];
  term:any;
  accountGroup: any;
  constructor(
    private manage: ManagementService,
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.GetAccounts()
    this.FilterForm = this.fb.group({
      startDate:['',Validators.required],
      endDate:['',Validators.required],
      dashboard:false,
      username:sessionStorage.getItem('username'),
      session:(JSON.parse(sessionStorage.getItem('userData'))).session,
      accountId:[''],
    })
  }


  GetAccounts()
  {
    this.spinner.show()
    let body ={
      Session:(JSON.parse(sessionStorage.getItem('userData'))).session,
      Username:sessionStorage.getItem('username'),
      SubsidiaryId:(JSON.parse(sessionStorage.getItem('userData'))).getSubsidiaries[0].subsidiaryId
    }
   this.manage.FetchAccounts(body).subscribe(
     res=>{
       if(res.success)
       {
        this.allAccounts = res.accounts
        this.accountGroup = res.accountGroup
         this.accounts  = res.accounts.slice(0,3);
        let acc = (this.accounts.filter(function(e){
          return e.primaryAccount;              
       }))
       this.defaultAcc = acc
       this.selectedNumber =  this.defaultAcc[0].accountNumber;
       this.defId =  this.defaultAcc[0].accountId;
       this.GetAccountHistory(this.defId)
       } 
     },
     err=>{
       this.spinner.hide()
       this.toastr.error('Unable to fetch accounts', 'Redirecting...')
       setTimeout(() => {
          this.router.navigate(['/login'])
       }, 1000);
     }
   )
  }

  GetAccountHistory(id)
  {
    var mytoday = new Date();
    var firstDay = new Date(mytoday.getFullYear(), mytoday.getMonth(), 1);
    var startDate = firstDay.toISOString().slice(0,10);
    var endDate =  mytoday.toISOString().slice(0,10); 
    let body = {
      AccountId: id,
      Session: (JSON.parse(sessionStorage.getItem('userData'))).session,
      Username: sessionStorage.getItem('username'),
      StartDate: '2021-05-01',
      Enddate: endDate,
      dashboard:false
    } 
    this.manage.FetchHistory(body).subscribe(
      res=>{
        this.spinner.hide()
        if(res.success)
        {
          this.allHistory = res.result;
          this.transHistory = this.allHistory;
        }
        else{
          this.toastr.error('Something went wrong', 'Taking you home...')
          setTimeout(() => {
            this.router.navigate(['/dashboard']);
          }, 1500);
        }
      },
      err=>{
        this.spinner.hide();
        this.toastr.error('Unable to complete that request','Redirecting...')
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 1500);
      }
    )
  }

  sortHistory(data)
  {
    if(data === 'all')
    {
      this.transHistory = this.allHistory
    }
    else{
        this.transHistory = this.allHistory.filter(function (e) {
        return e.drcr === data;              
      });
    }
  }

  filterHistory(data)
  { 
    this.spinner.show()
    this.FilterForm.get('accountId').setValue(this.defId)
    this.manage.FetchHistory(this.FilterForm.value).subscribe(
      res=>{
        this.spinner.hide()
        if(res.success)
        {
          this.allHistory = res.result;
          this.transHistory = this.allHistory;
        }
        else{
          this.toastr.error('Something went wrong', 'Taking you home...')
          setTimeout(() => {
            this.router.navigate(['/dashboard']);
          }, 1500);
        }
      },
      err=>{
        this.spinner.hide();
        this.toastr.error('Unable to complete that request','Redirecting...')
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 1500);
      }
    )
  }

  downloadStatement()
  {
    if(this.FilterForm.invalid)
    {
      this.toastr.error('Please select statement period')
      return false;
    }
    this.FilterForm.get('accountId').setValue(this.defId)
    this.manage.getPDF(this.FilterForm.value).subscribe(
      res=>{
        this.spinner.hide()
          if(res)
          {   
          this.toastr.success('Downloading...')             
          const newBlob = new Blob([(res)], { type: 'application/pdf' });
        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }
        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const downloadURL = URL.createObjectURL(newBlob);
          window.open(downloadURL);
          }
          else{
            this.toastr.error('Something went wrong', 'Taking you home...')
            setTimeout(() => {
              this.router.navigate(['/dashboard']);
            }, 1500);
          }
      },
      err=>{
        this.spinner.hide();
        this.toastr.error('Unable to complete that request','Redirecting...')
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 1500);
      }
    )
  }

  switchAcc(data)
  {
    this.spinner.show()
    this.selectedNumber = data.accountNumber;
    this.GetAccountHistory(data.accountId)
  }
  cutString(data)
  {
    if (data.length > 11) {
       return data = data.substring(0, 10) + "...";
    }
  }
  ViewAccounts(event: any)
  {
    let value = JSON.parse(event.target.value)
    // for(let i = 0; i < this.accounts.length; i++)
    // {
    //   if(value.accountNumber === this.accounts[i].accountNumber)
    //     {
    //       return false;
    //     }
    // }

    if(event.target.checked)
    {
      for(let i = 0; i < this.accounts.length; i++)
        {
          if(value.accountNumber === this.accounts[i].accountNumber)
            {
              console.log('we match')
              return false;
            }
            else{
              this.accounts.push(value)
              return;
            }
        }
    }
    else{
      this.accounts = this.accounts.filter(function (e) {
        return e.accountNumber !== value.accountNumber;              
      });
    }
  }

  transformString(data)
  {
    return JSON.stringify(data);
  }
  sliceArray(data)
  {
    return data.slice(0,3)
  }

}
