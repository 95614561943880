import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const userData = sessionStorage.getItem('userData');
    const userName = sessionStorage.getItem('username')

    if(!userData && !userName) {
      sessionStorage.clear();
      this.router.navigate(['/login']);
      return false;
    }else{
      return true;
    }


  }
  
}