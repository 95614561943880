<app-header></app-header>
<div class="page-wrapper">
    <app-sidebar></app-sidebar>   
        
        <!-- MAIN CONTENT-->
        <main class="main-content">
            <div class="main-content-padding">
                <div class="accounts-overview-div p-4">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="d-flex justify-content-between align-items-center">
                                <h3 class="div-title font-blue font-14px">Accounts  Overview</h3>
                                <a href="javascript:void(0);" [routerLink]="['/accounts-management/account-center']" class="font-10px f-weight400">
                                    See all <i class="fa fa-caret-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3 account-card-wrapper">
                        <a href="javascript:void(0);" class="col-lg-4 col-md-6 mb-4 d-flex flex-column flex-grow-1" *ngFor="let item of accounts; let i = index" >
                            <div class="account-card p-3 h-100" [ngClass]="{'active': selectedNumber === item.accountNumber}" (click)="switchAcc(item)">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 mt-2">
                                        <div>
                                            <h4 class="font-blueGrey font-16px acct-name">{{cutString(item?.accountName)}}</h4>
                                            <p class="font-blueGrey font-12px acct-numb">{{item?.accountNumber}}</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 mt-2">
                                        <div class="card-btn-grid">
                                            <div class="">
                                                <button class="btn account-card-btn">PAY</button>
                                            </div>
                                            <div class="">
                                                <button class="btn account-card-btn">PURCHASE</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-lg-12">
                                        <div class="d-flex justify-content-between">
                                            <h1 class="font-20px font-orange">{{item?.currency}} <span id="balance{{i}}">{{item?.availableBalance | number: '.2'}}</span></h1>
                                            <button class="btn amount-visibility"><img src="./assets/images/crossed-eye.svg" class="img-fluid" alt=""></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="mt-3">
                    <div class="transaction-trend-div p-4" style="height: 80vh;">
                        <div class="row justify-content-between">
                            <div class="col-lg-8">
                                <div class="row align-items-center">
                                    <div class="col-xl-3 col-lg-4 col-md-6 col-6">
                                        <h3 class="div-title font-blue font-16px mb-0">Spend trend<span class="px-2"><img src="./assets/images/tool-tip-icon.svg" class="img-fluid tooltip-icon-size" alt="" data-toggle="tooltip" data-placement="top" data-title="this is a tooltip nnsnsnnsnsnsnsnsnsn"></span></h3>
                                    </div>
                                    <div class="col-xl-4 col-lg-5 col-md-6 col-6 px-0">
                                        <!-- <div>
                                            <button  class="menu-btn" type="button">
                                                Select account <img src="./assets/images/blue-caret-down.svg" class="img-fluid" alt="">
                                            </button>
                                            <div class="d-none shadow rounded menu">
                                                <span class="d-block menu-option"><label class="mb-0 font-12px d-flex align-items-center"><input type="checkbox">&nbsp;
                                                    Halal foods - 928924</label></span>
                                                <span class="d-block menu-option"><label class="mb-0 font-12px d-flex align-items-center"><input type="checkbox">&nbsp;
                                                    Mega Chicken - 9029002</label></span>
                                                <span class="d-block menu-option"><label class="mb-0 font-12px d-flex align-items-center"><input type="checkbox">&nbsp;
                                                    Balogun and Balogun - 9223..</label></span>
                                            </div>
                                        </div> -->
                                        <div class="d-none" id="overlay" ></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-4">
                                <div class="row justify-content-end">
                                    <div class="col-lg-5 col-xl-5">
                                        <a href="javascript:void(0);" class="font-12px f-weight400" (click)="calculateStyles('weekly')">Weekly view</a>
                                    </div>
                                    <div class="col-lg-5 col-xl-5">
                                        <a href="javascript:void(0);" class="font-12px f-weight400" (click)="calculateStyles('monthly')">Monthly view</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-100 align-items-center mt-3">
                            <ngx-charts-line-chart
                            [view]="view"
                            [scheme]="colorScheme"
                            [legend]="legend"
                            [showXAxisLabel]="showXAxisLabel"
                            [showYAxisLabel]="showYAxisLabel"
                            [xAxis]="xAxis"
                            [yAxis]="yAxis"
                            [xAxisLabel]="xAxisLabel"
                            [yAxisLabel]="yAxisLabel"
                            [timeline]="timeline"
                            [results]="single"
                            (select)="onSelect($event)"
                            (activate)="onActivate($event)"
                            (deactivate)="onDeactivate($event)"
                            >
                          </ngx-charts-line-chart>
                        </div>                        
                    </div>
                </div>

                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-6 d-flex flex-column flex-grow-1">
                            <div class="workplace-summary bg-white p-4 h-100">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <h3 class="div-title font-blue font-14px">Workspace Summary</h3>
                                            <a href="#" class="font-10px f-weight400">
                                                Go to workspace <i class="fa fa-caret-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-4 col-lg-6 mt-3 d-flex flex-column flex-grow-1">
                                        <div class="workplace-summary-divs h-100">
                                            <img src="./assets/images/total-transaction-icon.svg" class="img-fluid" alt="Total transactions">
                                            <h6 class="pt-3">{{Summary?.totalTransaction}}</h6>
                                            <h6 class="fs-12">Completed Transactions</h6>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 mt-3 d-flex flex-column flex-grow-1">
                                        <div class="workplace-summary-divs h-100">
                                            <img src="./assets/images/completed-tasks-icon.svg" class="img-fluid" alt="Total transactions">
                                            <h6 class="pt-3">{{Summary?.completedTransaction}}</h6>
                                            <h6 class="fs-12">Total Transactions</h6>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 mt-3 d-flex flex-column flex-grow-1">
                                        <div class="workplace-summary-divs h-100">
                                            <img src="./assets/images/pending-tasks-icon.svg" class="img-fluid" alt="Total transactions">
                                            <h6 class="pt-3">{{Summary?.pendingTransaction}}</h6>
                                            <h6 class="fs-12">Pending Transactions</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 d-flex flex-column flex-grow-1">
                            <div class="exchange-rate bg-white p-4 h-100">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <h3 class="div-title font-blue font-14px">Exchange Rates</h3>
                                            <a href="#" class="font-10px f-weight400">
                                                Go to Exchange Rate <i class="fa fa-caret-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12 mt-3">
                                        <div class="exchange-rate-div py-4">
                                            <div class="table-responsive">
                                                
                                                <table class="table table-borderless">                                                    
                                                    <tbody *ngIf="exchangeRate?.length">
                                                        <tr>
                                                            <th class="font-blue font-12px">Currency</th>
                                                            <ng-container *ngFor="let item of exchangeRate">                                                           
                                                                    <th class="font-blue font-12px">{{item.currencyName}}</th>                                                                
                                                            </ng-container>  
                                                        </tr>         
                                                        <tr>
                                                            <th class="font-blue font-12px">Rate BUY/SELL</th>
                                                            <ng-container *ngFor="let item of exchangeRate">
                                                                    <td class="font-blue font-12px">{{item.buy}}/{{item.sell}}</td>
                                                            </ng-container>   
                                                        </tr>                                             
                                                    </tbody>
                                                    <ng-container *ngIf="!exchangeRate?.length">
                                                        <div class="p-5">
                                                            <h6>Exchange Rate Data Not Available Currently</h6>
                                                        </div>
                                                    </ng-container>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-3 transaction-history-div p-4">
                    <div class="row justify-content-between">
                        <div class="col-lg-4 col-xs-4">
                            <h3 class="div-title font-blue font-14px">Transaction History</h3>
                        </div>
                        <!-- <div class="col-xl-3 col-lg-4 col-xs-8">
                            <div class="row justify-content-end">
                                <div class="col-lg-5 col-xl-5">
                                    <a href="#" class="font-lightBlue font-10px f-weight400">Funds Transfer</a>
                                </div>
                                <div class="col-lg-5 col-xl-5">
                                    <a href="#" class="font-blue font-10px f-weight400">Bills Payment</a>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-12">
                            <div class="table-responsive table-div">
                                <table class="table table-borderless table-transaction-history">
                                    <thead>
                                        <tr>
                                            <td>TRANSACTION DATE</td> 
                                            <td>VALUE DATE</td>
                                            <td>DESCRIPTION</td>
                                            <td>TYPE</td>
                                            <td>AMOUNT</td>                                           
                                            <td>BALANCE</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let history of transHistory?.result; let i = index">
                                            <td>{{history?.transDate}}</td>
                                            <td>{{history?.valueDate}}</td>
                                            <td>{{history?.narration}}</td>                                           
                                            <td>
                                                <ng-container *ngIf="history?.drcr === 'C'">
                                                    <div class="green-credit"></div>
                                                </ng-container>
                                                <ng-container *ngIf="history?.drcr === 'D'">
                                                    <div class="red-debit"></div>
                                                </ng-container>
                                            </td> 
                                            <td> 
                                                <ng-container *ngIf="history?.drcr === 'C'">
                                                    <span class="text-success">
                                                        {{history?.transAmount | number: '1.2-2'}}
                                                    </span>
                                                </ng-container>
                                                <ng-container *ngIf="history?.drcr === 'D'">
                                                    <span class="text-danger">
                                                        {{history?.transAmount | number: '1.2-2'}}
                                                    </span>
                                                </ng-container>
                                            </td>                                            
                                            <td>{{history?.balance | number: '1.2-2'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div >
                                    <div style="background-color: #fff; height: auto;padding: 5%;" *ngIf="!transHistory?.result.length">
                                        <div class="d-flex align-items-center flex-column">
                                            <div class="p-2">
                                                <img src="assets/images/utils/empty-trans.svg" />
                                            </div>
                                            <div class="p-2 text-center mt-4">
                                                  <h6>No Transaction History</h6>
                                                  <p>You haven’t performed any transaction yet.<br /> Completed transaction will show up here.</p>
                                            </div>
                                            <div class="p-2">
                                                <a class="btn send-money" [routerLink]="['/transfer-services']">Send Money</a>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    <!-- MAIN CONTENT-->
  
    <!-- END MAIN CONTENT-->
    <!-- END PAGE CONTAINER-->
</div>


