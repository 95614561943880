import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { HttpClientModule } from "@angular/common/http";
import { SidebarComponent } from './sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { SkeletonLoaderComponent } from './shared/skeleton-loader/skeleton-loader.component';


@NgModule({
  declarations: [HeaderComponent, SidebarComponent, SkeletonLoaderComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule
  ],
  exports:[HeaderComponent, SidebarComponent]
})
export class CoreModule { }
