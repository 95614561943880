import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ManagementService } from 'src/app/core/services/management.service';

@Component({
  selector: 'app-exchange-rate',
  templateUrl: './exchange-rate.component.html',
  styleUrls: ['./exchange-rate.component.css']
})
export class ExchangeRateComponent implements OnInit {
  exchangeRates: any;
  p:any;
  constructor(
    private manage: ManagementService,
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.GetRates()
  }

  GetRates()
  {
    this.spinner.show()
    let body = {
      Session:(JSON.parse(sessionStorage.getItem('userData'))).session,
      Username:sessionStorage.getItem('username'),
      SubsidiaryId:(JSON.parse(sessionStorage.getItem('userData'))).getSubsidiaries[0].subsidiaryId
    }
    this.manage.FetchRates(body).subscribe(
      res =>{
        this.spinner.hide()
        if(res.success)
        {
         this.exchangeRates = res.exchangeRate[0].currencies
        }
        else{
          this.toastr.error('Rates are unavailable currently','Taking you home...')
          setTimeout(() => {
            this.router.navigate(['/dashboard']);
          }, 1500);
        }
      },
      err=>{
        this.spinner.hide()
        this.toastr.error('Unable to complete that request','Redirecting...')
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 1500);
      }
    )
  }

}
