<main>
    <div class="login-top-bar">
        <div class="container">
            <div class="row">
                <div class=col-lg-12>
                    <div class="d-flex justify-content-end px-1">
                        <img src="./assets/images/FirstPay-Plus-LOGO-white.svg" class="img-fluid" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="log-in-area">
        <div class="login-area-overlay">

        </div>
        <div class="container">
            <div class="d-flex align-items-center flex-wrap log-in-main justify-content-around px-2">
                <div class="col-xl-5 col-lg-6 col-12">
                    <div class="mt-5">
                        <h4 class="text-light f-weight700 font-24px">Transact Easily</h4>
                        <div class="yellow-horizontal-line mt-4"></div>

                        <h3 class="f-weight400 text-light font-28px text-light mt-4">Flexible and Reliable Business <br/>Banking</h3>
                        <div class="col-lg-6 px-0 mt-4">
                            <button class="btn get-started-btn">GET STARTED</button>
                        </div>
                    </div>
                </div>
                <div class="col-xl-5 col-lg-6 mt-5 col-12">
                    <ng-container *ngIf="!InitiatePassword">
                        <form [formGroup]="ResetForm" (ngSubmit)="InitiateReset(ResetForm.value)">
                            <div class="login-form d-flex flex-column justify-content-center">
                                <h6 class="font-16px f-weight400 font-blue text-center">Enter your username to reset password</h6>
                                <div class="mt-5">
                                    <div class="position-relative">
                                        <input type="text" class="log-in-fields" formControlName="username" placeholder="Username">
                                        <div class="position-absolute login-form-icons">
                                            <i class="fa fa-building icon-color"></i>
                                        </div>
                                    </div>
                                    <div class="position-relative">
                                        <input type="text" class="log-in-fields" formControlName="corpCode" placeholder="Corporate Code">
                                        <div class="position-absolute login-form-icons">
                                            <i class="fa fa-user icon-color"></i>
                                        </div>
                                    </div>
                                    <div class="mt-4">
                                        <button type="submit" class="btn btn-login" [disabled]="ResetForm.invalid">Reset Password</button>
                                    </div>
                                    <div class="d-flex justify-content-between mt-2">
                                        <p class="font-12px f-weight700">Got an account?<a href="javascript:void(0);" [routerLink]="['/login']" >Login </a></p>
                                        <a href="javascript:void(0);" [routerLink]="['/reset-password']" class="font-12px f-weight700">Reset Password</a>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </ng-container>
                    <ng-container *ngIf="InitiatePassword">
                        <form [formGroup]="ResetPassForm" (ngSubmit)="ResetPassword(ResetPassForm.value)">
                            <div class="login-form d-flex flex-column justify-content-center">
                                <h6 class="font-18px f-weight400 font-blue text-center">Enter Default password and new password</h6>
                                <div class="mt-4">
                                    <div class="position-relative">
                                        <input type="password" class="log-in-fields" id="defPass" formControlName="oldPassword" placeholder="Default Password">
                                        <div class="position-absolute login-form-icons">
                                            <i class="fa fa-lock icon-color"></i>
                                        </div>
                                        <div class="position-absolute login-form-icons" (click)="Reveal('defPass')" style="left: 90% !important;">
                                            <i class="fa fa-eye icon-color"></i>
                                        </div>
                                    </div>
                                    <div class="position-relative">
                                        <input type="password" class="log-in-fields" id="newPass" formControlName="newPassword" placeholder="New Password">
                                        <div class="position-absolute login-form-icons" >
                                            <i class="fa fa-lock icon-color"></i>
                                        </div>
                                        <div class="position-absolute login-form-icons"  (click)="Reveal('newPass')" style="left: 90% !important;">
                                            <i class="fa fa-eye icon-color"></i>
                                        </div>
                                    </div>
                                    <div class="position-relative">
                                        <input type="password" class="log-in-fields" id="conPass" formControlName="conPassword" placeholder="Confirm Password">
                                        <div class="position-absolute login-form-icons" >
                                            <i class="fa fa-lock icon-color"></i>
                                        </div>
                                        <div class="position-absolute login-form-icons"  (click)="Reveal('conPass')"style="left: 90% !important;">
                                            <i class="fa fa-eye icon-color"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <label class="col mb-0" [ngClass]="ResetPassForm.controls['newPassword'].hasError('required') || ResetPassForm.controls['newPassword'].hasError('minlength')  ? 'text-danger' : 'text-success'">
                                            <small>
                                                Must be at least 8 characters!
                                            </small>                                
                                          </label>
                                          <label class="col mb-0" [ngClass]="ResetPassForm.controls['newPassword'].hasError('required') || ResetPassForm.controls['newPassword'].hasError('hasNumber')  ? 'text-danger' : 'text-success'">
                                            <small>
                                                Must contain at least 1 number!
                                            </small>                                
                                          </label>
                                          <label class="col mb-0" [ngClass]="ResetPassForm.controls['newPassword'].hasError('required') || ResetPassForm.controls['newPassword'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">
                                            <small>
                                                Must contain at least 1 in Capital Case!
                                            </small>                                
                                          </label>
                                          <label class="col mb-0" [ngClass]="ResetPassForm.controls['newPassword'].hasError('required') || ResetPassForm.controls['newPassword'].hasError('hasSmallCase')  ? 'text-danger' : 'text-success'">
                                           <small>
                                               Must contain at least 1 Letter in Small Case! 
                                           </small>
                                          </label>
                                          <label class="col mb-0" [ngClass]="ResetPassForm.controls['newPassword'].hasError('required') || ResetPassForm.controls['newPassword'].hasError('hasSpecialCharacters') ? 'text-danger' : 'text-success'">
                                            <small>
                                            Must contain at least 1 Special Character!
                                            </small>
                                          </label>
                                    </div>
                                    <div class="mt-4">
                                        <button type="submit" class="btn btn-login" [disabled]="ResetPassForm.invalid">Reset Password</button>
                                    </div>
                                    
                                    <div class="d-flex justify-content-between mt-2">
                                        <p class="font-12px f-weight700">Got an account?<a href="javascript:void(0);" [routerLink]="['/login']" >Login </a></p>
                                        <a href="javascript:void(0);" [routerLink]="['/reset-password']" class="font-12px f-weight700">Reset Password</a>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </ng-container>
                    
                </div>
            </div>
        </div>
       
    </div>
    <div class="login-bottom-bar"></div>
</main>