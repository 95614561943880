<app-header></app-header>
<div class="page-wrapper">
    <app-sidebar></app-sidebar> 
     <main class="main-content">
        <div class="main-content-padding">
            <div class="mb-3">
                <h5 class="font-18px text-grey">Account Management</h5>
            </div>
            <div class="accounts-overview-div p-4">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="d-flex justify-content-between align-items-center">
                            <h3 class="div-title font-blue font-16px">Exchange rates</h3>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-lg-12">
                        <div class="table-responsive table-div">
                            <table class="table table-borderless table-transaction-history">
                                <thead>
                                    <tr>
                                        <td>S/N</td>
                                        <td>CURRENCY</td>
                                        <td>BUY/SELL</td>
                                        <td>BUY/SELL</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of exchangeRates | paginate: { itemsPerPage: 3, currentPage: p } ; let i = index;">
                                        <td>{{i+1}}</td>
                                        <td>{{item?.currencyName}}</td>
                                        <td>
                                            {{item?.buy}} / {{item?.sell}}
                                        </td>
                                        <td> {{item?.buy}} / {{item?.sell}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="float-right">
                                 <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>