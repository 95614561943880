import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { single } from './data'
import * as Highcharts from "highcharts";

declare var $:any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  accounts: any;
  single:any[];
  defaultAcc: any;
  Summary: any;
  transHistory: any;
  weekly:boolean = true;
  dataSet=[
    {
      "name": "1990",
      "value": 62000000
    },
    {
      "name": "2010",
      "value": 73000000
    },
    {
      "name": "2011",
      "value": 89400000
    }
  ]
  multi:any;
  view: any[] = [700, 500];

  // options
  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Amount';
  yAxisLabel: string = 'Period';
  timeline: boolean = true;

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  }; 

  exchangeRate: any;
  selectedNumber: any;
  defId: any;

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private dash: DashboardService,
    private router: Router
  ) {
   }

  ngOnInit(): void {

    this.GetAccounts() 
   
  }

  GetAccounts()
  {
    this.spinner.show()
    let body ={
      Session:(JSON.parse(sessionStorage.getItem('userData'))).session,
      Username:sessionStorage.getItem('username'),
      SubsidiaryId:(JSON.parse(sessionStorage.getItem('userData'))).getSubsidiaries[0].subsidiaryId
    }
   this.dash.FetchAccounts(body).subscribe(
     res=>{
       this.spinner.hide()
       if(res.success)
       {
         if(res.accounts.length > 0 )
         {
            this.accounts  = res.accounts.slice(0,3);
              let acc = (this.accounts.filter(function(e){
                return e.primaryAccount;              
            }))
            this.defaultAcc = acc       
            this.selectedNumber =  this.defaultAcc[0].accountNumber;
            this.defId =  this.defaultAcc[0].accountId;
            this.GetTransactionHistory(body, this.defId)
            this.GetSpendPattern(body, this.defId)
         }
       this.GetRates(body)
       this.GetSummary(body)
       } 
     },
     err=>{
       this.spinner.hide()
       this.toastr.error('Unable to fetch accounts', 'Redirecting...')
       setTimeout(() => {
          this.router.navigate(['/login'])
       }, 1000);
     }
   )
  }
  GetRates(data)
  {
    this.dash.FetchRates(data).subscribe(
      res=>{
        this.spinner.hide()
        if(res.success)
        {
          this.exchangeRate = res.exchangeRate[0].currencies
        }
        else{
          this.toastr.error('Something went wrong with the rates report','Please try again later')
        }
      },
      err=>
      {
        this.spinner.hide()
        this.toastr.error('Unable to retrieve exchange rates', 'Please try later')
      }
    )
  }

  GetSummary(data)
  {
    this.dash.FetchSummary(data).subscribe(
      res=>{
        if(res.success)
        {
          this.Summary = res;
        }
        else{
          this.toastr.error('Unable to Retrieve Work Summary')
        }
      },
      err=>{
        this.toastr.error('Workspace summary unavailable right now','Please Check later!')
      }
    )
  }
  GetTransactionHistory(data, id)
  { 
    var mytoday = new Date();
    var firstDay = new Date(mytoday.getFullYear(), mytoday.getMonth(), 1);
    var startDate = firstDay.toISOString().slice(0,10);
    var endDate =  mytoday.toISOString().slice(0,10); 
    let body = {
      AccountId: id,
      Session: data.Session,
      Username: data.Username,
      StartDate: '2021-05-01',
      Enddate:endDate,
      dashboard:true
    }
    this.dash.FetchHistory(body).subscribe(
      res=>{
        if(res.success)
        {
          this.transHistory = res;
        }
        else{
          this.toastr.error('Error Fetching Transaction History','Please try again')
        }       
      }
      ,err=>{
        this.toastr.error('Unable to Fetch history')
      }
    )
  }

  GetSpendPattern(data,defaultAccount)
  {
    console.log(data, defaultAccount)
    let body = {
      Week: this.weekly,
      Session: data.Session,
      Username: data.Username,
      SubsidiaryId: data.SubsidiaryId,
      AccountId:defaultAccount
    }
    this.dash.FetchPattern(body).subscribe(
      res=>{
        this.spinner.hide()
        let dataSet = res.result.spendPattern.map(datum => ({ name:datum.date, value: datum.amount }));
       this.single= [
        {
          "name": "Savings",
          "series": dataSet
        }
       ]
      }
    )
  }

  switchAcc(data)
  {
    this.spinner.show()
    this.selectedNumber = data.accountNumber;
    this.GetTransactionHistory({
      Username:sessionStorage.getItem('username'),
      Session:(JSON.parse(sessionStorage.getItem('userData'))).session}
      ,data.accountId)
      this.GetSpendPattern(
        {Username:sessionStorage.getItem('username'),Session:(JSON.parse(sessionStorage.getItem('userData'))).session}
      , data.accountId)

  }
  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
  cutString(data)
  {
    if (data.length > 11) {
       return data = data.substring(0, 10) + "...";
    }
  }

  calculateStyles(data)
  {
    this.spinner.show()
    if(data === 'monthly')
    {
      this.weekly = false
    }
    this.GetSpendPattern(
      {Username:sessionStorage.getItem('username'),Session:(JSON.parse(sessionStorage.getItem('userData'))).session,SubsidiaryId:(JSON.parse(sessionStorage.getItem('userData'))).getSubsidiaries[0].subsidiaryId}
    , this.defId)
  }
 

}
