import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/services/auth.service';
import { CustomValidators } from 'src/app/core/classess/custom-validator';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Router } from '@angular/router';
declare var $:any;
@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {
  ResetForm: FormGroup;
  InitiatePassword:boolean = false;
  ResetPassForm: FormGroup;
  constructor(
    private fb:FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private auth: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.ResetForm = this.fb.group({
      username: ['', [Validators.required, Validators.minLength(3)]],
      corpCode: ['', [Validators.required]],
    });
    this.ResetPassForm = this.fb.group({
      oldPassword: ['',[Validators.required, Validators.minLength(3)]],
      newPassword: ['', Validators.compose([
        //check if there is a number
        CustomValidators.patternValidator(/\d/,{
          hasNumber:true
        }),
        //check wether the entered password has upper case letter
        CustomValidators.patternValidator(/[A-Z]/,{
          hasCapitalCase: true
        }),
        //check if it has lower case letter
        CustomValidators.patternValidator(/[a-z]/,{
          hasSmallCase: true
        }),
        //check for special chars
          CustomValidators.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,{
            hasSpecialCharacters:true
          }),
          Validators.minLength(8)
      ])],
      conPassword:['', RxwebValidators.compare({fieldName:'newPassword'})]
    });
  }

  InitiateReset(data)
  {
    let body = 
      {
        username: data.username+'@'+data.corpCode,
        ipAddress:'10.1.1.2',
        MacAddress: "00:1B:44:11:3A:B7"
      } 
    this.spinner.show()
    this.auth.InitiatePasswordChange(body).subscribe(
      res=>{
        this.spinner.hide()
          if(res.success)
          {
            this.toastr.success('Default Password sent to registered email','Kindly check your email')
            this.InitiatePassword = true;
            sessionStorage.setItem('session',res.session)
          }
          else{
            this.toastr.error('User not found', 'Please try again')
          }
      },
      err=>{
        this.spinner.hide()
        this.toastr.error('Unable to complete that request','Please try again')
      }
    )
  }

  ResetPassword(data)
  {
    this.spinner.show()
    let body = {
      username: this.ResetForm.value.username,
      session: sessionStorage.getItem('session'),
      oldPassword: this.ResetPassForm.value.oldPassword,
      newPassword: this.ResetPassForm.value.newPassword,
      ipAddress:'10.1.1.2',
      MacAddress: "00:1B:44:11:3A:B7"
    }
    this.auth.ResetPassword(body).subscribe(
      res=>{
        this.spinner.hide()
        if(res.success)
        {
          this.toastr.success('Password Changed Successfully', 'Redirecting to login...')
          setTimeout(() => {
            this.router.navigate(['/login'])
          }, 2000);
          this.ResetPassForm.reset();
        }
        else{
          this.toastr.error('Something went wrong','Please try again');
        }
      },
      err=>{
        this.spinner.hide();
        this.toastr.error('Unable to complete the request','Please try again')
      }
    )
  }

  Reveal(data:string)
  {
    let word = $('#'+data).attr("type")
    if(word == "password")
    {
      $("#"+data).attr("type","text");
    }
    else{
      $("#"+data).attr("type","password");
    }
  }


}
