 import { Routes } from '@angular/router';
import { AuthGuard } from './core/globals/auth.guard';
import { AccountsComponent } from './pages/account-management/accounts/accounts.component';
import { ExchangeRateComponent } from './pages/account-management/exchange-rate/exchange-rate.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { PasswordResetComponent } from './pages/auth/password-reset/password-reset.component';
import { UsernameResetComponent } from './pages/auth/username-reset/username-reset.component';
import { ChartComponent } from './pages/chart/chart.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { TransferComponent } from './pages/transfer/transfer.component';


export const AppRoutes:Routes = [
    
    { path:'' , redirectTo:'dashboard', pathMatch:'full' },
    { path:'login', component:LoginComponent },
    { path:'reset-username', component:UsernameResetComponent },
    { path:'reset-password', component:PasswordResetComponent },
    { path:'dashboard', canActivate:[AuthGuard], component:DashboardComponent},
    { path:'account-management/account-center', component:AccountsComponent},
    { path:'account-management/exchange-rate', component:ExchangeRateComponent},
    { path:'transfer/single-payments', component:TransferComponent },
    { path:'chart', component:ChartComponent }

]