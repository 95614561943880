import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './auth/login/login.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TransferComponent } from './transfer/transfer.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { UsernameResetComponent } from './auth/username-reset/username-reset.component';
import { PasswordResetComponent } from './auth/password-reset/password-reset.component';
import { AccountsComponent } from './account-management/accounts/accounts.component';
import { ExchangeRateComponent } from './account-management/exchange-rate/exchange-rate.component';
import { ChartComponent } from './chart/chart.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [LoginComponent, DashboardComponent, TransferComponent, UsernameResetComponent, PasswordResetComponent, AccountsComponent, ExchangeRateComponent, ChartComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgxChartsModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    FormsModule,
    Ng2SearchPipeModule,
    CoreModule
  ]
})
export class PagesModule { }
