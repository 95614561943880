<div class="page-wrapper">
    <app-header></app-header>
    <app-sidebar></app-sidebar>

    <div class="page-container">

        <div class="main-content">
            <div class="section__content section__content--p30">
                <div class="p-4 accounts-overview-div">
                    <h5 class="font-blue font-16px f-weight500">Single Payment</h5>
                    <div class="single-payment-form mt-5">
                        <form [formGroup]="SingleForm">
                            <div class="form-row align-items-center">
                                <div class="col-lg-3">
                                    <label for="transfer-from" class="mb-0 font-14px font-blue f-weight500">Transfer funds from</label>
                                </div>
                                <div class="col-xl-4 col-lg-5">
                                    <select class="custom-select mr-sm-2 input-field" id="inlineFormCustomSelect">
                                        <option selected>Select account</option>
                                        <option value="{{item.accountNumber}}" *ngFor="let item of localResponse?.accounts">{{item?.accountName}}-{{item.accountNumber}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-row align-items-center mt-4">
                                <div class="col-lg-3">
                                    <label for="transfer-from" class="mb-0 font-14px font-blue f-weight500">Select beneficiary type</label>
                                </div>
                                <div class="col-xl-7 col-lg-8">
                                    <div class="row align-items-center ml-1">
                                        <div class="form-check col-lg-4 align-items-center d-flex" (click)="setBeneficiary('saved')">
                                            <input class="form-check-input" type="radio" name="gridRadios" id="saved">
                                            <label class="form-check-label radio-labels-text" for="saved">
                                                Saved beneficiary
                                            </label>
                                        </div>
                                        <div class="form-check col-lg-4 align-items-center d-flex" (click)="setBeneficiary('new')">
                                            <input class="form-check-input" type="radio" name="gridRadios" id="new">
                                            <label class="form-check-label radio-labels-text" for="new">
                                                New beneficiary
                                            </label>
                                        </div>
                                        <div class="form-check col-lg-4 align-items-center d-flex" (click)="setBeneficiary('self')">
                                            <input class="form-check-input" type="radio" name="gridRadios" id="self-linked">
                                            <label class="form-check-label radio-labels-text" for="self-linked">
                                                Self-linked account
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="beneficiaryType === 'saved'">
                                <ng-container  *ngFor="let beneficiaries of t.controls; let i = index">
                                    <div class="form-row align-items-center mt-4">
                                        <div class="col-lg-3">
                                            <label for="transfer-from" class="mb-0 font-14px font-blue f-weight500">Beneficiary name</label>
                                        </div>
                                        <div class="col-xl-4 col-lg-5">
                                            <select class="custom-select mr-sm-2 input-field" id="inlineFormCustomSelect">
                                                <option value="{{item.accountNumber}}" *ngFor="let item of localResponse?.beneficiaries">{{item?.accountName}}-{{item.accountNumber}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-row align-items-center mt-4">
                                        <div class="col-lg-3">
                                            <label for="transfer-from" class="mb-0 font-14px font-blue f-weight500">Amount</label>
                                        </div>
                                        <div class="col-xl-4 col-lg-5">
                                            <div class="d-flex">
                                                <div class="w-33">
                                                    <select class="custom-select input-field" id="inlineFormCustomSelect">
                                                        <option value="1" selected>NGN</option>
                                                    </select>
                                                </div>
                                                <div class="w-67">
                                                    <input type="text" formControlName="amount" class="form-control input-field">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row align-items-center mt-4">
                                        <div class="col-lg-3">
                                            <label for="transfer-from" class="mb-0 font-14px font-blue f-weight500">Narration</label>
                                        </div>
                                        <div class="col-xl-4 col-lg-5">
                                            <input type="text" formControlName="narration" class="form-control input-field">
                                        </div>
                                    </div>
                                    <div class="">
                                        <div class="mb-3">
                                            <a class="text-primary fs-12" (click)="onAddBeneficiary()">Add beneficiary</a>
                                        </div>
                                        <div class="mb-3">
                                            <a class="text-danger fs-12" (click)="removeBeneficiary(i)">Remove beneficiary</a>
                                        </div>
                                    </div>
                                    
                                </ng-container>
                               
                            </div>

                           
                            <div class="form-row align-items-center mt-4" *ngIf="beneficiaryType === 'new'">
                                <div class="col-lg-3">
                                    <label for="transfer-from" class="mb-0 font-14px font-blue f-weight500">Beneficiary name</label>
                                </div>
                                <div class="col-xl-4 col-lg-5">
                                    <select class="custom-select mr-sm-2 input-field" id="inlineFormCustomSelect">
                                        <option selected>Select beneficiary</option>
                                        <option value="1">Tosin Thomas</option>
                                        <option value="2">Oyinade Apeke</option>
                                        <option value="3">Lawal Adewale</option>
                                      </select>
                                </div>
                            </div>

                            <!-- <div class="form-row align-items-center mt-4">
                                <div class="col-lg-3">
                                    <label for="transfer-from" class="mb-0 font-14px font-blue f-weight500">Amount</label>
                                </div>
                                <div class="col-xl-4 col-lg-5">
                                    <div class="d-flex">
                                        <div class="w-33">
                                            <select class="custom-select input-field" id="inlineFormCustomSelect">
                                                <option value="1" selected>NGN</option>
                                                <option value="2">USD</option>
                                              </select>
                                        </div>
                                        <div class="w-67">
                                            <input type="text" class="form-control input-field">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-row align-items-center mt-4">
                                <div class="col-lg-3">
                                    <label for="transfer-from" class="mb-0 font-14px font-blue f-weight500">Narration</label>
                                </div>
                                <div class="col-xl-4 col-lg-5">
                                    <input type="text" class="form-control input-field">
                                </div>
                            </div> -->
                            <div class="my-5">
                                <hr class="thick-hr" />
                            </div>

                            <div class="form-row align-items-center">
                                <div class="col-lg-3">
                                    <label for="transfer-from" class="mb-0 font-14px font-blue f-weight500">Payment method</label>
                                </div>
                                <div class="col-xl-7 col-lg-8">
                                    <div class="row align-items-center ml-1">
                                        <div class="form-check col-lg-4 align-items-center d-flex">
                                            <input class="form-check-input" type="radio" name="paymentType" id="instant">
                                            <label class="form-check-label radio-labels-text" for="Instant">
                                                Instant payment
                                            </label>
                                        </div>
                                        <div class="form-check col-lg-4 align-items-center d-flex">
                                            <input class="form-check-input" type="radio" name="paymentType" id="NEFT">
                                            <label class="form-check-label radio-labels-text" for="NEFT">
                                                NEFT
                                            </label>
                                        </div>
                                        <div class="form-check col-lg-4 align-items-center d-flex">
                                            <input class="form-check-input" type="radio" name="paymentType" id="RTGS">
                                            <label class="form-check-label radio-labels-text" for="RTGS">
                                                RTGS
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row align-items-center mt-4">
                                <div class="col-lg-3">
                                    <label for="transfer-from" class="mb-0 font-14px font-blue f-weight500">Value date</label>
                                </div>
                                <div class="col-xl-4 col-lg-5">
                                    <input type="date" class="form-control input-field">
                                </div>
                            </div>

                            <div class="form-row align-items-center mt-4">
                                <div class="col-lg-3">
                                    <label for="transfer-from" class="mb-0 font-14px font-blue f-weight500">Attachment</label>
                                </div>
                                <div class="col-xl-4 col-lg-5">
                                    <input type="file" class="d-none" id="fileUpload">
                                    <button class="btn input-field" id="openFileUpload">
                                        Upload file from computer <span class="px-2"><img src="assets/images/upload-file-icon.svg" class="img-fluid" alt=""></span>
                                    </button>
                                </div>
                            </div>
                            <div class="form-row mt-5 justify-content-end">
                                <div class="col-xl-2 col-lg-3">
                                    <button type="submit" class="btn btn-submit" (click)="ViewSubmission()">Submit</button>
                                </div>
                                <div class="col-xl-2 col-lg-3">
                                    <button class="btn btn-schedule">Schedule Batch</button>
                                </div>
                            </div>
                        </form>
                    </div> 
                </div>
                <div class="mt-3 transaction-history-div p-4">
                    <div class="row justify-content-between">
                        <div class="col-lg-4 col-xs-4">
                            <h3 class="div-title font-blue font-14px">Single Payment Batch</h3>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-xs-8">
                            <div class="row justify-content-end">
                                <div class="col-lg-5 col-xl-5">
                                    <a href="#" class="font-lightBlue font-10px f-weight400">Funds Transfer</a>
                                </div>
                                <div class="col-lg-5 col-xl-5">
                                    <a href="#" class="font-blue font-10px f-weight400">Bills Payment</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-12 mt-2">
                            <div class="">
                                <input type="date" class="date-select-field"  placeholder="Search by type or amount">
                            </div> 
                        </div>
                        <div class="col-lg-3 col-12 mt-2">
                            <div class="position-relative">
                                <input type="text" class="table-search-field" placeholder="Search by type or amount">
                                <div class="position-absolute search-icon-position">
                                    <img src="./assets/images/search-icon.svg" class="img-fluid" alt="Search">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-12">
                            <div class="table-responsive">
                                <table class="table table-borderless table-transaction-history">
                                    <thead>
                                        <tr>
                                            <td>TRANS ID</td>
                                            <td>REF CODE</td>
                                            <td>ACCOUNT NUMBER</td>
                                            <td>BENEFICIARY BANK</td>
                                            <td>BENEFICIARY NUBAN</td>
                                            <td>SORT CODE</td>
                                            <td>BENEFICIARY NAME</td>
                                            <td>AMOUNT</td>
                                            <td></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><a class="font-dark">00001</a></td>
                                            <td>08OSDC8N</td>
                                            <td>42082984</td>
                                            <td>GT Bank</td>
                                            <td>OUHNCWODU</td>
                                            <td>42978492</td>
                                            <td>Treasure LTD</td>
                                            <td>N200,000</td>
                                            <td><a href="#"><img src="./assets/images/table-delete-icon.svg" class="img-fluid" alt="delete"></a></td>
                                        </tr>
                                        <tr>
                                            <td><a class="font-dark">00002</a></td>
                                            <td>08OSDC8N</td>
                                            <td>42082984</td>
                                            <td>GT Bank</td>
                                            <td>OUHNCWODU</td>
                                            <td>42978492</td>
                                            <td>Treasure LTD</td>
                                            <td>N200,000</td>
                                            <td><img src="./assets/images/table-delete-icon.svg" class="img-fluid" alt="delete"></td>
                                        </tr>
                                        <tr>
                                            <td><a class="font-dark">00003</a></td>
                                            <td>08OSDC8N</td>
                                            <td>42082984</td>
                                            <td>GT Bank</td>
                                            <td>OUHNCWODU</td>
                                            <td>42978492</td>
                                            <td>Treasure LTD</td>
                                            <td>N200,000</td>
                                            <td><img src="./assets/images/table-delete-icon.svg" class="img-fluid" alt="delete"></td>
                                        </tr>
                                        <tr>
                                            <td><a class="font-dark">00004</a></td>
                                            <td>08OSDC8N</td>
                                            <td>42082984</td>
                                            <td>GT Bank</td>
                                            <td>OUHNCWODU</td>
                                            <td>42978492</td>
                                            <td>Treasure LTD</td>
                                            <td>N200,000</td>
                                            <td><img src="./assets/images/table-delete-icon.svg" class="img-fluid" alt="delete" style="width:24px; height:24px;"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row mt-5 justify-content-end">
                                <div class="col-xl-2 col-lg-3">
                                    <button class="btn btn-submit">Submit batch</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal" tabindex="-1" role="dialog" id="submissionSuccessful" backdrop="static">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Modal title</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <p>Modal body text goes here.</p>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-primary">Save changes</button>
                      <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
            </div>
        </div>
        <!-- END MAIN CONTENT-->
        <!-- END PAGE CONTAINER-->
    </div>

</div>
