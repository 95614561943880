<header>
    <nav class="navbar navbar-expand-lg navbar-light bg-light navbar-custom p-3 shadow-sm">
        <a class="navbar-brand" href="#"><img src="./assets/images/FirstPay-Plus-LOGO-blue.svg" alt=""></a>
      
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <div class="mr-auto">
              <h5 class="ml-95">Dashboard </h5>
          </div>
          <div class="dropdown change-susidiary">
            <a href="#" class="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img src="./assets/images/subsidiary-icon.svg" alt=""><span class="px-2 font-grey font-12px">Change subsidiary</span>
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" *ngFor="let item of subsidiaries">{{item?.subsidiaryName}}</a>
            </div>
        </div>
        <div class="dropdown change-susidiary">
            <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img src="./assets/images/question.svg" alt=""><span class="px-2 font-grey font-12px">Help</span>
            </a>
          </div>
        <div class="dropdown user-profile">
            <a href="#" class="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img src="./assets/images/user-profile-icon.svg" alt=""><span class="px-2 font-grey font-12px">{{fullName}}</span>
            </a>
            <div class="dropdown-menu p-3" aria-labelledby="dropdownMenuButton">
                <button class="btn btn-primary" (click)="Logout()">Logout</button>
            </div>
        </div>
        </div>
    </nav>
</header>