<div class="pt-90">
    <button class="menu-button px-3">
        <img src="./assets/images/menu.svg" class="img-fluid" alt="" style="height:24px; width:24px;">
    </button>
    <div class="side-nav">
        <ul class="list-unstyled side-nav-item">
            <li class="nav-item"  [routerLink]="['/dashboard']" [routerLinkActive]="['active']" >
                <a href="javascript:void(0);" class="nav-link">
                    Dashboard
                </a>
            </li>
            <li class="nav-item dropdown" [routerLink]="['accounts']"  [routerLinkActive]="['active']">
                <a href="#" class="nav-link dropdown-toggle"  id="dropdownMenuButton" data-toggle="dropdown"  aria-haspopup="true" aria-expanded="false">
                    <i class="fa fa-tasks"></i>
                    Account Management
                </a>
                <div class="dropdown-menu mt-3 change-dropdown-bg" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" href="javascript:void(0);" [routerLink]="['/account-management/account-center']" >Accounts</a>
                    <a class="dropdown-item" href="javascript:void(0);" [routerLink]="['/account-management/exchange-rate']">Exchange Rate</a>
                </div>
            </li>
            <li class="nav-item"  [routerLink]="['/transfer']"  [routerLinkActive]="['active']" >
                <a href="javascript:void(0);" class="nav-link">
                    Transfer Services
                </a>
            </li>
            <li class="nav-item">
                <a href="javascript:void(0);" class="nav-link">
                    Cheque Services
                </a>
            </li>
            <li class="nav-item">
                <a href="javascript:void(0);"class="nav-link">
                    Trade
                </a>
            </li>
            <li class="nav-item">
                <a href="javascript:void(0);" class="nav-link">
                    Bill Payment
                </a>
            </li>
            <li class="nav-item">
                <a href="javascript:void(0);" class="nav-link">
                    Reports
                </a>
            </li>
        </ul>
        <hr class="hr-menu" />
        <ul class="list-unstyled side-nav-item">
            <li class="nav-item">
                <a href="javascript:void(0);" class="nav-link">
                    Settings
                </a>
            </li>
            <li class="nav-item">
                <a href="javascript:void(0);" class="nav-link">
                    Notifications
                </a>
            </li>
        </ul>
    </div>
</div>