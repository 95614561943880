<app-header></app-header>
<div class="page-wrapper">
    <app-sidebar></app-sidebar> 
        <main class="main-content">
        <div class="main-content-padding">
            <div class="mb-3">
                <h5 class="font-18px text-grey">Account Management</h5>
            </div>
            <div class="accounts-overview-div p-4">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="d-flex justify-content-between align-items-center">
                            <h3 class="div-title font-blue font-16px">Accounts</h3>
                            <a href="#" class="font-10px f-weight400">
                                See all <i class="fa fa-caret-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="row mt-3 justify-content-between account-card-wrapper">
                    <div class="col-lg-3">
                        <div class="total-accounts">
                            <div>
                                <table class="table table-borderless mb-0">
                                    <tbody>
                                        <tr>
                                            <td class="font-14px">Company accounts</td>
                                        </tr>
                                        <tr>
                                            <td class="font-16px f-weight500">{{allAccounts?.length}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4" *ngFor="let item of accountGroup">
                        <div class="naira-account">
                            <div>
                                <table class="table table-borderless mb-0">
                                    <tbody>
                                        <tr>
                                            <td class="font-14px">Total Available balance</td>
                                            <td class="font-14px text-right">Total Ledger balance</td>
                                        </tr>
                                        <tr> 
                                            <td class="font-14px f-weight500 font-blue">{{item?.currency}}&nbsp;{{item?.totalAvailableBalance | number: '.2'}}</td>
                                            <td class="font-14px f-weight500 font-blue text-right">{{item?.currency}} &nbsp; {{item?.totalLedgerBalance | number: '.2'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-3 transaction-history-div p-4">
                <div class="row justify-content-between">
                    <div class="col-lg-4 col-xs-4">
                        <h3 class="div-title font-blue font-16px">Account list<span class="px-2"><img src="./assets/images/tool-tip-icon.svg" class="img-fluid tooltip-icon-size" alt="" data-toggle="tooltip" data-placement="top" data-title="this is a tooltip nnsnsnnsnsnsnsnsnsn"></span></h3>
                        <div class="mt-4">
                            <h6 class="font-14px font-lightBlack">Select an account for more details</h6>
                            <!-- <div>
                                <button onclick="dropDown(event);" class="menu-btn" type="button">
                                    Select account <img src="./assets/images/blue-caret-down.svg" class="img-fluid" alt="">
                                </button>
                                <div class="d-none shadow rounded menu">
                                    <span class="d-block menu-option" *ngFor="let item of allAccounts; let i = index">
                                        <label class="mb-0 font-12px d-flex align-items-center" for="item{{i}}">
                                            <input type="checkbox" (change)="ViewAccounts($event)" value="{{ transformString(item)}}" id="item{{i}}"
                                            >&nbsp;
                                            {{item?.accountName}} - {{item?.accountNumber}}
                                        </label>
                                    </span>
                                </div>
                            </div> -->
                            <div class="d-none" id="overlay" onclick="hide(event)"></div>
                        </div>
                    </div>    
                </div>
                <div class="row mt-4 account-card-wrapper">
                    <div class="col-lg-4 col-md-6 mb-4 d-flex flex-column flex-grow-1"  *ngFor="let item of accounts; let i = index">
                        <div class="account-card-mgt h-100" [ngClass]="{'active': selectedNumber === item?.accountNumber}" (click)="switchAcc(item)">
                            <div class="px-3">
                                <div class="mt-3 row justify-content-between align-items-center">
                                    <div class="col-lg-6 col-md-6">
                                        <h6 class="font-14px mb-0">Savings account</h6>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <p class="mb-0 d-flex justify-content-end">
                                            <input type="radio" id="test{{i}}" name="radio-group" checked="{{item?.primaryAccount ? 'checked': ''}}">
                                            <label for="test{{i}}" class="mb-0 font-12px">Set as default</label>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr class="yellow-underline mb-3" />
                            <div class="px-3">
                                <div class="row">
                                    <div class="col-md-6">
                                        <!--<p class="font-blueGrey font-12px mb-0">Account</p>-->
                                        <h5 class="font-16px f-weight500 mb-0">{{cutString(item?.accountName)}}</h5>
                                        <p class="font-14px">{{item?.accountNumber}}</p>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="d-flex flex-column align-items-end">
                                            <p class="font-12px mb-0">Currency</p>
                                            <h5 class=" font-16px f-weight500 mb-0">{{item?.currency}}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="px-3 my-3">
                                <div class="row align-items-end">
                                    <div class="col-md-6">
                                        <p class="font-12px mb-0">Available balance</p>
                                        <h5 class="font-16px f-weight500 mb-0">{{item?.availableBalance}}</h5>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="d-flex justify-content-end ">
                                            <a href="javascript:void(0);" class="font-10px f-weight500 text-right text-right">
                                                View account summary <i class="fa fa-caret-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="account-summary-div mt-4">
                    <div class="row">
                        <div class="col-lg-12">
                            <h3 class="div-title font-blue font-16px mb-0">Account Summary<span class="px-2"><img src="./assets/images/tool-tip-icon.svg" class="img-fluid tooltip-icon-size" alt="" data-toggle="tooltip" data-placement="top" data-title="this is a tooltip nnsnsnnsnsnsnsnsnsn"></span></h3>
                        </div>
                    </div>
                    <div class="row justify-content-between align-items-center mt-3">
                        <div class="col-xl-8 col-md-12 col-12 mb-3">
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <form [formGroup]="FilterForm" (ngSubmit)="filterHistory(FilterForm.value)">
                                        <div class="d-flex">
                                            <div class="">
                                                <input type="date" class="date-select-field" formControlName="startDate">
                                            </div>
                                            <div class="px-3 align-self-center">
                                                <span class="text-muted">TO</span>
                                            </div>
                                            <div class="">
                                                <input type="date" class="date-select-field" formControlName="endDate">
                                            </div>
                                            <div class="">
                                                <button class="btn-sm btn btn-primary" [disabled]="FilterForm.invalid">GO</button>
                                            </div>
                                        </div>  
                                    </form>                                                                     
                                </div>
                                <!-- <div class="col-lg-3">
                                    <div class="">
                                        <input type="date" class="date-select-field">
                                    </div>
                                </div> -->
                                <div class="col-lg-3 col-12">
                                    <div class="position-relative">
                                        <input type="text" class="table-search-field"  [(ngModel)]="term" placeholder="Input Search Term">
                                        <div class="position-absolute search-icon-position">
                                            <img src="./assets/images/search-icon.svg" class="img-fluid" alt="Search">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <a href="javascript:void(0);" (click)="downloadStatement()" class="font-12px f-weight500">Download statement<span><img src="./assets/images/pdf-icon.svg" class="img-fluid" alt=""></span></a>
                                </div>
                            </div> 
                        </div>
                        <div class="col-xl-3 col-md-6 mb-3">
                            <div class="btn-group btn-group-toggle" data-toggle="buttons">
                                <label class="btn active" (click)="sortHistory('all')">
                                  <input type="radio" name="options" id="option1" checked> All
                                </label>
                                <label class="btn" (click)="sortHistory('D')">
                                  <input type="radio" name="options" id="option2"> Debit
                                </label>
                                <label class="btn" (click)="sortHistory('C')">
                                  <input type="radio" name="options" id="option3"> Credit
                                </label>
                            </div>
                        </div>                            
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-12">
                            <div class="table-responsive table-div">
                                <table class="table table-borderless table-transaction-history">
                                    <thead>
                                        <tr>
                                            <td>SN/N</td>
                                            <td>TRANSACTION DATE</td>
                                            <td>VALUE DATE</td>
                                            <td>DESCRIPTION</td>
                                            <td>AMOUNT</td>                                            
                                            <td>BALANCE</td>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="transHistory?.length">
                                        <tr *ngFor="let item of (transHistory | filter:term) | paginate: { itemsPerPage: 20, currentPage: p } ; let i = index">
                                            <td>{{i+1}}</td>
                                            <td>{{item?.transDate}}</td>
                                            <td>{{item?.valueDate}}</td>
                                            <td>{{item?.narration}}</td>
                                            <td>
                                                <ng-container *ngIf="item?.drcr === 'C'">
                                                    <span class="text-success">
                                                        {{item?.transAmount | number: '1.2-2'}}
                                                    </span>
                                                </ng-container>
                                                <ng-container *ngIf="item?.drcr === 'D'">
                                                    <span class="text-danger">
                                                        {{item?.transAmount | number: '1.2-2'}}
                                                    </span>
                                                </ng-container>
                                            </td>                                            
                                            <td>{{item?.balance | number: '1.2-2'}}</td>
                                        </tr>                                       
                                    </tbody>                                     
                                </table>
                                    <ng-container *ngIf="transHistory?.length">
                                            <div class="float-right">
                                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                                        </div>
                                    </ng-container>                                   
                                <div >
                                    <div style="background-color: #fff; height: auto;padding: 5%;" *ngIf="!transHistory?.length">
                                        <div class="d-flex align-items-center flex-column">
                                            <div class="p-2">
                                                <img src="assets/images/utils/empty-trans.svg" />
                                            </div>
                                            <div class="p-2 text-center mt-4">
                                                  <h6>No Transaction History</h6>
                                                  <p>You haven’t performed any transaction yet.<br /> Completed transactions will show up here.</p>
                                            </div>
                                            <div class="p-2">
                                                <a class="btn send-money" [routerLink]="['/transfer/single-payments']">Initiate Payment</a>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>